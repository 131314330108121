import {JobModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"

import {PositionListComponent} from "../PositionsSectionComponent"

import styles from "./PositionList.module.scss"
import PositionNewListItem from "./PositionNewListItem"

const PositionList = (props: PositionListComponent) => {
	return (
		<div className={styles.list__container}>
			<ul className={styles.list}>
				{props.data.map((element: JobModuleType, index: number) => {
					return <PositionNewListItem key={index} data={element} />
				})}
			</ul>
		</div>
	)
}

export default PositionList
