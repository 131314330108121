import Paragraph from "@components/CustomTypography/Paragraph/Paragraph"
import PopupSwitcher from "@components/Switchers/PopupSwitcher/PopupSwitcher"
import ButtonComponent from "@components/Theme/Button/Button"
import {JobModuleType} from "@hooks/WorkWithJson/GetComponentDataByKey/GetComponentDataByKeyTypes"
import Link from "next/link"
import React, {useState} from "react"

import styles from "./PositionNewListItem.module.scss"
type Props = {
	data: JobModuleType
}

const PositionNewListItem = (props: Props) => {
	const href = "/careers/" + String(props.data.slug)

	const [popup, setPopup] = useState<boolean>(false)
	const changePopup = (): void => {
		setPopup(!popup)
	}

	return (
		<li className={styles.list}>
			<Link
				className={styles.linkContainer}
				href={href}
				rel={"noreferrer"}
			>
				<Paragraph
					className={`${styles.text} `}
					content={props.data.title}
					fontWeight={600}
					size={"XS"}
				/>
				<Paragraph
					className={`${styles.text} `}
					content={props.data.department[0].title}
					size={"XS"}
				/>
				<Paragraph
					className={`${styles.text} `}
					content={props.data.location[0].title}
					size={"XS"}
				/>
				<ButtonComponent
					buttonType={"secondary"}
					className={styles.linkItem}
					size={"default"}
				>
					Apply Now!
				</ButtonComponent>
			</Link>
			<div className={styles.playButton}>
				<ButtonComponent
					buttonType={"unstyled"}
					size={"middle"}
					playIcon
					onClick={changePopup}
				/>
				{popup && (
					<PopupSwitcher
						data={{
							...props.data,
							insideContent: {inside: {...props.data.inside}},
						}}
						handleClick={changePopup}
						type={"asset"}
					/>
				)}
			</div>
		</li>
	)
}

export default PositionNewListItem
